
define(['jquery', 'stapes', 'slick'], function ($, Stapes, slick, undefined) {
    "use strict";

    var BREAKPOINT_MD = 984;
    var BREAKPOINT_SM = 768;
    var BREAKPOINT_XS = 550;


    function getResponsiveConfig(baseConfigData, responsiveData){
        return $.extend(baseConfigData, {
            responsive: [
                {
                    breakpoint: BREAKPOINT_MD,
                    settings: {
                        arrows: true,
                        dots: false,
                        slidesToShow: responsiveData['slidesToShow_medium'],
                        slidesToScroll: responsiveData['slidesToScroll_medium']
                    }
                },
                {
                    breakpoint: BREAKPOINT_SM,
                    settings: {
                        arrows: true,
                        dots: false,
                        slidesToShow: responsiveData['slidesToShow_small'],
                        slidesToScroll: responsiveData['slidesToScroll_small']
                    }
                },
                {
                    breakpoint: BREAKPOINT_XS,
                    settings: {
                        arrows: false,
                        dots: true,
                        slidesToShow: responsiveData['slidesToShow_Xsmall'],
                        slidesToScroll: responsiveData['slidesToScroll_Xsmall']
                    }
                }
            ]
        });
    }


    var SliderSlick = Stapes.subclass({
        defaults: {},

        constructor : function(element, options) {
            this.options = $.extend({}, this.defaults, options);
            this.$element = $(element);

            // var slickType = this.$element.data('slick-type');
            // if (this['init_' + slickType]) {
            //     this['init_' + slickType]();
            // }
            this.init_single();

        },

        init_single: function() {
            var $slider = this.$element.find(' > .slider-container__slider').first();

            var slidesToShow_large = this.$element.data('slick_slidestoshow_large');
            slidesToShow_large = !!slidesToShow_large ? slidesToShow_large : 1;

            var slidesToShow_medium = this.$element.data('slick_slidestoshow_medium');
            slidesToShow_medium = !!slidesToShow_medium ? slidesToShow_medium : slidesToShow_large;

            var slidesToShow_small = this.$element.data('slick_slidestoshow_small');
            slidesToShow_small = !!slidesToShow_small ? slidesToShow_small : slidesToShow_medium;

            var slidesToShow_Xsmall = this.$element.data('slick_slidestoshow_xsmall');
            slidesToShow_Xsmall = !!slidesToShow_Xsmall ? slidesToShow_Xsmall : slidesToShow_small;

            var slidesToScroll_large = this.$element.data('slick_slidestoscroll_large');
            slidesToScroll_large = !!slidesToScroll_large ? slidesToScroll_large : 1;

            var slidesToScroll_medium = this.$element.data('slick_slidestoscroll_medium');
            slidesToScroll_medium = !!slidesToScroll_medium ? slidesToScroll_medium : slidesToScroll_large;

            var slidesToScroll_small = this.$element.data('slick_slidestoscroll_small');
            slidesToScroll_small = !!slidesToScroll_small ? slidesToScroll_small : slidesToScroll_medium;

            var slidesToScroll_Xsmall = this.$element.data('slick_slidestoscroll_xsmall');
            slidesToScroll_Xsmall = !!slidesToScroll_Xsmall ? slidesToScroll_Xsmall : slidesToScroll_small;

            var variableWidth = this.$element.hasClass('slider-container--page-preview');

            var slick_slider = $slider.slick(getResponsiveConfig({
                slidesToShow: slidesToShow_large,
                slidesToScroll: slidesToScroll_large,
                dots: false,
                arrows: true,
                infinite: false,
                adaptiveHeight: false,
                variableWidth: false
            }, {
                slidesToShow_medium: slidesToShow_medium,
                slidesToShow_small: slidesToShow_small,
                slidesToShow_Xsmall: slidesToShow_Xsmall,
                slidesToScroll_medium: slidesToScroll_medium,
                slidesToScroll_small: slidesToScroll_small,
                slidesToScroll_Xsmall: slidesToScroll_Xsmall
            }));

            // var $slide_item = $slider.find('.slide-item').first();
            // var width = parseInt($slide_item.css('width'));
            // var margin_right = parseInt($slide_item.css('margin-right'));
            // var margin_left = parseInt($slide_item.css('margin-left'));
            // var slidesToShow = slidesToShow_large;
            //
            // $slider.find('.slick-list').css({
            //     width: (slidesToShow * width) + ((slidesToShow - 1) * margin_right) + ((slidesToShow - 1) * margin_left) + 10
            // });
            //
            // $slider.on('breakpoint', function (event, slick, breakpoint) {
            //     console.log(breakpoint);
            //     var $slide_item = $slider.find('.slide-item').first();
            //     var width = parseInt($slide_item.css('width'));
            //     var margin_right = parseInt($slide_item.css('margin-right'));
            //     var margin_left = parseInt($slide_item.css('margin-left'));
            //     var slidesToShow = slidesToShow_large;
            //     if(breakpoint) {
            //         if( breakpoint == BREAKPOINT_TABLET ) {
            //             slidesToShow = slidesToShow_medium;
            //         }
            //         if ( breakpoint == BREAKPOINT_MOBILE ) {
            //             slidesToShow = slidesToShow_small;
            //         }
            //     }
            //
            //     $slider.find('.slick-list').css({
            //         width: (slidesToShow * width) + ((slidesToShow - 1) * margin_right) + ((slidesToShow - 1) * margin_left) + 10
            //     });
            // });
        }

    });

    return SliderSlick;
});
