;
define(['jquery', 'stapes', 'modules/Responsive', 'debounce'], function ($, Stapes, Responsive, debounce, undefined) {
    "use strict";

    var MobileMenu = Stapes.subclass({
        defaults: {
            body_active_class: 'is-mobile-mainmenu-active'
        },

        constructor: function (options) {
            var responsive = Responsive.init();
            this.current_resolution = responsive.get('current');

            this.options = $.extend({}, this.defaults, options);
            var self = this;

            this.$mobile_menu = $('.mobile-mainmenu');
            this.$burger = $('.mobile-mainmenu-burger');

            this.mm_left_pos = this.$mobile_menu.css('left');

            this.$mobile_menu.find('ul.menu > li').each(function () {
                self.initSubMenus($(this));
            });

            if(this.$mobile_menu.length > 0)
                this.bindEvents();
        },

        initSubMenus: function ($li) {
            var self = this;
            var $sublist = $li.find('ul.menu').first();
            if($sublist.length > 0) {
                var parent_class = $li.data('parent_class');
                var clone = $li.clone().addClass('parent-link back-link').addClass(parent_class);
                clone.find('ul.menu').remove();
                clone.removeAttr('data-parent_class');
                $sublist.prepend(clone);
                $li.addClass('has-children');
                self.bindSubmenuEvents($li);
            }
            $sublist.find('> li').each(function () {
                self.initSubMenus($(this));
            });
        },

        bindSubmenuEvents: function ($li) {
            var self = this;
            var $sublist = $li.find('ul.menu').first();
            var $parentlist = $li.parents('ul.menu').first();
            var $first_link = $li.find('> a').first();
            if($first_link.length <= 0)
                $first_link = $li.find('> span.menu-item').first();

            $first_link.on('click', function (e) {
                e.preventDefault();
                $sublist.show().stop().animate({
                    left: 0
                }, 300);
                $parentlist.scrollTop(0).css({
                    overflow: 'scroll'
                });
            });

            $sublist.find('> li.back-link > a').on('click', function (e) {
                e.preventDefault();
                $sublist.stop().animate({
                    left: self.mm_left_pos
                }, 300, function () {
                    $sublist.hide();
                    $parentlist.css({
                        overflow: 'scroll'
                    })
                });
            });
        },

        bindEvents: function () {
            var self = this;

            this.$burger.on('click', function () {
                self.handleBurgerClick();
            });
        },

        handleBurgerClick: function () {
            if ($('body').hasClass(this.defaults.body_active_class)) {
                this.hideMobileMenu();
            } else {
                this.showMobileMenu();
            }
        },

        showMobileMenu: function () {
            $('body').addClass(this.defaults.body_active_class);

            this.$mobile_menu.stop().animate({
                left: 0
            }, 300);
        },

        hideMobileMenu: function () {

            this.$mobile_menu.stop().animate({
                left: this.mm_left_pos
            }, 300, function () {
                    $('body').removeClass(this.defaults.body_active_class);
            }.bind(this));
        }
    });

    return MobileMenu;
});
