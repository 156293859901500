;
define(['jquery', 'stapes', 'modules/Responsive', 'debounce'], function ($, Stapes, Responsive, debounce, undefined) {
    "use strict";

    var EqualizeHeightFormRows = Stapes.subclass({
        defaults: {
            attribute: 'equalize',
            attribute_small: 'equalize_small'
        },

        constructor: function ($targets, options) {
            var responsive = Responsive.init();
            this.current_resolution = responsive.get('current');

            this.$targets = $targets;
            this.options = $.extend({}, this.defaults, options);
            var self = this;

            this.data_equalize_group_identifier = 'equalize_group_large';
            if (this.current_resolution == "md") {
                this.data_equalize_group_identifier = 'equalize_group_medium';
            }
            if (this.current_resolution == "sm") {
                this.data_equalize_group_identifier = 'equalize_group_small';
            }

            this.equalize();

            // $targets.find('img').bind('load', function() { self.equalize(); });
            // $(document).bind('ready resize', function() { self.equalize(); });
            // $(window).bind('resize', function() { self.equalize(); });
            //
            $(window).bind('resolutionChanged.responsive.oekotest', function (event, data) {
                self.current_resolution = data.newResolution;
                self.data_equalize_group_identifier = 'equalize_group_large';
                if (self.current_resolution == "md") {
                    self.data_equalize_group_identifier = 'equalize_group_medium';
                }
                if (self.current_resolution == "sm") {
                    self.data_equalize_group_identifier = 'equalize_group_small';
                }

                self.equalize();
            });

            $(window).bind('resize', debounce(function () {
                self.equalize();
            }, 50));
        },

        equalize: function () {
            this._height_adjust(this._sort_by_rel());
        },

        _sort_by_rel: function () {
            var $targets = this.$targets;
            var groups = {
                fallback: new Array()
            };
            var self = this;
            $targets.each(function (i, target) {
                var $target = $(target);
                var rel = $target.data(self.data_equalize_group_identifier);
                if (rel === undefined) {
                    groups.fallback.push($target);
                } else {
                    if (groups[rel] === undefined) {
                        groups[rel] = new Array();
                    }
                    groups[rel].push($target);
                }
            });
            return groups;
        },

        _height_adjust: function (target_groups) {
            for (var key in target_groups) {
                if(key == 'fallback') {
                    if (target_groups[key].length > 0) {
                        $.each(target_groups[key], function (i, item) {
                            var $item = $(item);
                            $item.css('height', 'auto');
                            $item.find('.form-field-wrapper').css('height', 'auto');
                            $item.find('.form-field').css('position', 'static');
                        });
                    }
                } else {
                    if (target_groups[key].length > 0) {
                        $.each(target_groups[key], function (i, item) {
                            var $item = $(item);
                            var $field_wrapper = $item.find('.form-field-wrapper');
                            if($field_wrapper.length <= 0) {
                                var $item_html = $item.html();
                                $item.html('');
                                $field_wrapper = $('<div>').addClass('form-field-wrapper');
                                $field_wrapper.html($item_html);
                                $item.append($field_wrapper);
                            }
                        });

                        var max_height = 0;
                        $.each(target_groups[key], function (i, item) {
                            var $item = $(item);
                            $item.css('height', 'auto');
                            $item.find('.form-field-wrapper').css('height', 'auto');
                            $item.find('.form-field').css('position', 'static');
                            max_height = max_height < $item.height() ? $item.height() : max_height;
                        });
                        $.each(target_groups[key], function (i, item) {
                            var $item = $(item);
                            var $field_wrapper = $item.find('.form-field-wrapper');
                            $field_wrapper.css({
                                position: 'relative',
                                height: max_height
                            });
                            $field_wrapper.find('.form-field').css({
                                position: 'absolute',
                                bottom: 0,
                                width: '100%'
                            });
                        });
                    }
                }
            }
        }

    });

    return EqualizeHeightFormRows;
});
