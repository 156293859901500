
define(['jquery', 'stapes', 'modules/Responsive', 'debounce'], function ($, Stapes, Responsive, debounce, undefined) {
    "use strict";

    var ExpandableBox = Stapes.subclass({
        defaults: {},
        use_on_lg: false,
        use_on_md: false,
        use_on_sm: false,
        is_set_expandable: false,
        current_expandable_height: 250,

        constructor: function (element, options) {
            var self = this;

            this.options = $.extend({}, this.defaults, options);
            this.$box = $(element);
            this.$box_content = this.$box.find('.box__content');

            this.expandable_height_lg = this.$box.data('expandable_height_lg');
            if(!!this.expandable_height_lg && !isNaN(this.expandable_height_lg)) {
                this.use_on_lg = true;
            }
            this.expandable_height_md = this.$box.data('expandable_height_md');
            if(!!this.expandable_height_md && !isNaN(this.expandable_height_md)) {
                this.use_on_md = true;
            }
            this.expandable_height_sm = this.$box.data('expandable_height_sm');
            if(!!this.expandable_height_sm && !isNaN(this.expandable_height_sm)) {
                this.use_on_sm = true;
            }

            this.expandable_text_more = this.$box.data('expandable_text_more');
            if(!this.expandable_text_more || this.expandable_text_more == '') {
                this.expandable_text_more = 'Weitere Inhalte ausklappen';
            }
            this.expandable_text_less = this.$box.data('expandable_text_less');
            if(!this.expandable_text_less || this.expandable_text_less == '') {
                this.expandable_text_less = 'Inhalte einklappen';
            }

            this.$expand_button = $('<a href="#">').addClass('box__expander-button').html(this.expandable_text_more);
            this.$expander = $('<div>').addClass('box__expander').append(this.$expand_button);

            var responsive = Responsive.init();
            var current_resolution = responsive.get('current');
            self.init_by_current_resolution(current_resolution);

            $(window).bind('resolutionChanged.responsive.oekotest', function (event, data) {
                self.init_by_current_resolution(data.newResolution);
            });

            $(window).bind('resize', debounce(function () {
                if(self.is_set_expandable && self.$box.hasClass('box--expandable-opened')) {
                    self.$box_content.css('height', 0);
                    self.$box_content.css({
                        height: self.$box_content.get(0).scrollHeight
                    });
                }
            }, 50));

        },

        init_by_current_resolution: function (current_resolution) {
            if (current_resolution == "xs" || current_resolution == "sm") {
                if(this.use_on_sm) {
                    this.current_expandable_height = this.expandable_height_sm;
                    this.set_expandable();
                } else {
                    this.reset_expandable();
                }
            }
            else if (current_resolution == "md") {
                if(this.use_on_md) {
                    this.current_expandable_height = this.expandable_height_md;
                    this.set_expandable();
                } else {
                    this.reset_expandable();
                }
            }
            else if (current_resolution == "lg") {
                if(this.use_on_lg) {
                    this.current_expandable_height = this.expandable_height_lg;
                    this.set_expandable();
                } else {
                    this.reset_expandable();
                }
            }
        },

        set_expandable: function () {
            if(!this.is_set_expandable) {
                this.$box_content.after(this.$expander);
                this.$box.addClass('box--expandable-closed');
                this.$box_content.css({
                    height: this.current_expandable_height
                });

                this.$expand_button.off('click').on('click', function (e) {
                    e.preventDefault();
                    this.handle_expand_click();
                }.bind(this));

                this.is_set_expandable = true;
            } else {
                if(this.$box.hasClass('box--expandable-closed')) {
                    this.$box_content.css({
                        height: this.current_expandable_height
                    });
                }
                if(this.$box.hasClass('box--expandable-opened')) {
                    this.$box_content.css('height', 0);
                    this.$box_content.css({
                        height: this.$box_content.get(0).scrollHeight
                    });
                }
            }
        },

        reset_expandable: function () {
            if(this.is_set_expandable){
                this.$expander.remove();
                this.$box.removeClass('box--expandable-closed').removeClass('box--expandable-opened');
                this.$box_content.css({
                    height: 'auto'
                });

                this.is_set_expandable = false;
            }
        },

        handle_expand_click: function () {
            if(this.$box.hasClass('box--expandable-closed')){
                this.open_expandable();
            } else {
                this.close_expandable();
            }
        },

        close_expandable: function () {
            this.$box.addClass('box--expandable-closed').removeClass('box--expandable-opened');
            this.$box_content.stop().animate({
                height: this.current_expandable_height
            }, 500);
            this.$expand_button.html(this.expandable_text_more);
        },

        open_expandable: function () {
            this.$box.removeClass('box--expandable-closed').addClass('box--expandable-opened');
            this.$box_content.stop().animate({
                height: this.$box_content.get(0).scrollHeight
            }, 500);
            this.$expand_button.html(this.expandable_text_less);
        }

    });

    return ExpandableBox;
});
