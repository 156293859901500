
define(['jquery', 'stapes', 'modules/Responsive', 'debounce'], function ($, Stapes, Responsive, debounce, undefined) {
    "use strict";

    var StickyHeader = Stapes.subclass({
        defaults: {},

        constructor: function (element, options) {
            var responsive = Responsive.init();
            this.current_resolution = responsive.get('current');

            this.options = $.extend({}, this.defaults, options);
            this.$element = $(element); // #header

            this.$placeholder = $('<div>').addClass('sticky-header-placeholder').insertBefore(this.$element);

            this.positionTop = this.$element.position().top;
            this.element_height = this.$element.outerHeight();

            this.handleScroll();
            this.bindEvents();
        },

        handleScroll: function() {
            var scroll_top = $(document).scrollTop();
            var body_padding = parseInt($('body').css('padding-top'));

            if ((this.current_resolution == "sm" || this.current_resolution == "xs") &&  scroll_top >= this.positionTop)
                this.set_sticky_header(body_padding);
            else
                this.reset_sticky_header();
        },

        bindEvents: function () {
            var self = this;

            $(window).on('resolutionChanged.responsive.oekotest', function (event, data) {
                self.current_resolution = data.newResolution;
                self.handleScroll();
            });

            $(document).on("scroll", function () {
                if($('#modal:visible').length == 1) {
                    return;
                }
                self.handleScroll();
            });

            $(window).on('resize', debounce(function () {
                self.reset_sticky_header();
                self.positionTop = self.$element.position().top;
                self.element_height = self.$element.outerHeight();
                self.handleScroll();
            }, 50));
        },

        set_sticky_header: function (body_padding) {
            this.$element.css({
                position: 'fixed',
                zIndex: 102,
                width: '100%',
                top: 0 + body_padding,
                left: 0
            });

            this.$placeholder.css({
                paddingTop: parseInt(this.element_height)
            });

            $('body').addClass('is-header-sticky');
        },

        reset_sticky_header: function() {
            this.$element.css({
                position: 'relative',
                width: 'auto',
                top: 'auto',
                left: 'auto'
            });

            this.$placeholder.css({
                paddingTop: 0
            });

            $('body').removeClass('is-header-sticky');
        }
    });

    return StickyHeader;
});
