;
define(['jquery', 'stapes', 'modules/Responsive', 'debounce'], function ($, Stapes, Responsive, debounce, undefined) {
    "use strict";

    var FlowingColumns = Stapes.subclass({
        defaults: {
            // attribute: 'equalize'
        },

        constructor: function (target, options) {
            var responsive = Responsive.init();
            this.current_resolution = responsive.get('current');

            this.$target = $(target);
            this.options = $.extend({}, this.defaults, options);
            var self = this;

            this.data_columns_identifier = 'columns_large';
            if (this.current_resolution == "md") {
                this.data_columns_identifier = 'columns_medium';
            }
            if (this.current_resolution == "sm") {
                this.data_columns_identifier = 'columns_small';
            }
            if (this.current_resolution == "xs") {
                this.data_columns_identifier = 'columns_small';
            }

            this.groups = [];
            if (this.$target.hasClass('col-grid')) {
                self.groups.push({
                    $wrapper: this.$target,
                    $items: this.$target.find('.col')
                });
            } else {
                var wrappers = this.$target.find('.col-grid');
                wrappers.each(function () {
                    self.groups.push({
                        $wrapper: $(this),
                        $items: $(this).find('.col')
                    });
                });
            }

            this.handle_groups();

            $(window).bind('resolutionChanged.responsive.oekotest', function (event, data) {
                self.current_resolution = data.newResolution;
                self.data_columns_identifier = 'columns_large';
                if (self.current_resolution == "md") {
                    self.data_columns_identifier = 'columns_medium';
                }
                if (self.current_resolution == "sm") {
                    self.data_columns_identifier = 'columns_small';
                }

                self.handle_groups();
            });

            $(window).bind('resize', debounce(function () {
                self.handle_groups();
            }, 50));

            for (var i = 0; i < this.groups.length; i++) {
                var group = this.groups[i];
                group.$wrapper.find('img').bind('load', function () {
                    self._reorder_items(group);
                });
            }
        },

        handle_groups: function () {
            var self = this;
            for (var i = 0; i < this.groups.length; i++) {
                var group = this.groups[i];

                if (this.current_resolution == "xs") {
                    self._reset(group);
                }
                else {
                    if (group.$wrapper.data(this.data_columns_identifier) > 1) {
                        self._reorder_items(group);
                    } else {
                        self._reset(group);
                    }
                }
            }
        },

        _reset: function (group) {
            group.$wrapper.css({
                position: 'static',
                height: 'auto'
            });
            group.$items.each(function (index, value) {
                $(value).css({
                    position: 'static',
                    left: 'inherit',
                    top: 'inherit'
                });
            });
        },

        _reorder_items: function (group) {
            var column_count = group.$wrapper.data(this.data_columns_identifier);

            group.$wrapper.css({
                position: 'relative',
                height: group.$wrapper.height()
            });

            var spacer = 0;
            var column_width = 0;
            var column_offsets = [];
            for (var i = 0; i < parseInt(column_count); i++) {
                column_offsets.push({
                    offset_left: 0,
                    offset_top: 0
                })
            }

            var self = this;
            group.$items.each(function (index, value) {
                var $item = $(value);
                $item.css({
                    position: 'absolute'
                });
                if (index == 0) {
                    spacer = $item.outerWidth(true) - $item.outerWidth();
                    column_width = $item.outerWidth();
                    var offset_left = 0;
                    for (var i = 0; i < column_offsets.length; i++) {
                        column_offsets[i].offset_left = offset_left;
                        offset_left += column_width + spacer;
                    }
                }

                var column_offset_id = self._get_smallest_offset_id(column_offsets);

                $item.css({
                    left: column_offsets[column_offset_id].offset_left,
                    top: column_offsets[column_offset_id].offset_top
                });

                column_offsets[column_offset_id].offset_top += $item.outerHeight(true);

            });

            var wrapper_height = self._get_biggest_offset_top(column_offsets);
            group.$wrapper.height(wrapper_height);

        },

        _get_smallest_offset_id: function (column_offsets) {
            var top = 0;
            var column_offset_id;
            for (var i = 0; i < column_offsets.length; i++) {
                if (i == 0) {
                    top = column_offsets[i].offset_top;
                    column_offset_id = i;
                }
                if (column_offsets[i].offset_top < top) {
                    top = column_offsets[i].offset_top;
                    column_offset_id = i;
                }
            }
            return column_offset_id
        },

        _get_biggest_offset_top: function (column_offsets) {
            var top = 0;
            for (var i = 0; i < column_offsets.length; i++) {
                if (column_offsets[i].offset_top > top) {
                    top = column_offsets[i].offset_top;
                }
            }
            return top
        }

    });

    return FlowingColumns;
});
