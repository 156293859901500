;
define(['jquery', 'stapes', 'modules/Responsive'], function ($, Stapes, Responsive, undefined) {
    "use strict";

    var ContactFilter = Stapes.subclass({
        defaults: {},

        constructor: function (options) {
            var responsive = Responsive.init();
            this.current_resolution = responsive.get('current');

            this.options = $.extend({}, this.defaults, options);

            this.$contact_filter_form = $('form.contact-filter-form').first();
            this.$contact_filter_select = this.$contact_filter_form.find('select').first();
            this.$contact_sections = $('.filterable-contact-section');

            this.hash_value = '';

            if(window.location.hash) {
                this.hash_value = window.location.hash.substring(1);
                this.$contact_filter_select.val(this.hash_value);
            } else {
                this.hash_value = this.$contact_filter_select.val();
            }

            if(this.$contact_filter_form.length > 0 && this.$contact_sections.length > 0) {
                this.init_by_current_resolution();
                this.bindEvents();
            }

        },

        init_by_current_resolution: function () {
            if (this.current_resolution == "xs" || this.current_resolution == "sm") {
                this.setSectionByHash();
            } else {
                this.resetSections();
            }
        },

        resetSections: function () {
            this.$contact_sections.each(function () {
                var $section = $(this);
                $section.show();
            });
        },

        setSectionByHash: function () {
            var self = this;
            this.$contact_sections.each(function () {
                var $section = $(this);
                $section.hide();

                if(self.hash_value && $section.attr('id') == self.hash_value) {
                    $section.show();
                }
            });

            if (window.history.replaceState) {
                var url = '#' + self.hash_value;
                window.history.replaceState({}, "", url);
            }
        },

        bindEvents: function () {
            var self = this;

            $(window).bind('resolutionChanged.responsive.oekotest', function (event, data) {
                this.current_resolution = data.newResolution;
                this.init_by_current_resolution();
            }.bind(this));

            this.$contact_filter_select.on('change', function () {
                self.hash_value = self.$contact_filter_select.val();
                self.init_by_current_resolution();
            });
        }
    });

    return ContactFilter;
});
