define([], function () {
    "use strict";

    var viewPortHack = function() {
        /*
         * for screens < 360px: scale using the viewport attr
         */

        var viewport = $('meta[name="viewport"]');
        if (screen.width < 360) {
            var scale = screen.width / 360;
            viewport.attr("content", "width=360, user-scalable=no, initial-scale=" + scale);
        } else {
            viewport.attr("content", "width=device-width, initial-scale=1.0, user-scalable=no");
        }
    };

    return viewPortHack;
});