
define(['jquery', 'stapes', 'modules/Responsive'], function ($, Stapes, Responsive, undefined) {
    "use strict";

    var FooterAccordion = Stapes.subclass({
        defaults: {},
        is_set_accordion: false,

        constructor: function (element, options) {
            var self = this;

            this.options = $.extend({}, this.defaults, options);
            this.$footer_block = $(element);
            this.$footer_block_header = this.$footer_block.find('.footer-block__header');
            this.$footer_block_content = this.$footer_block.find('.footer-block__content');

            var responsive = Responsive.init();
            var current_resolution = responsive.get('current');
            self.init_by_current_resolution(current_resolution);

            $(window).bind('resolutionChanged.responsive.oekotest', function (event, data) {
                self.init_by_current_resolution(data.newResolution);
            });
        },

        init_by_current_resolution: function (current_resolution) {
            if (current_resolution == "xs" || current_resolution == "sm") {
                this.set_accordion();
            }
            else {
                this.reset_accordion();
            }
        },

        set_accordion: function () {
            if(!this.is_set_accordion) {
                this.$footer_block.addClass('footer-block--is-collapsed');
                this.$footer_block_content.css({
                    height: 0
                });

                this.$footer_block_header.off('click').on('click', function (e) {
                    e.preventDefault();
                    this.handle_click();
                }.bind(this));

                this.is_set_accordion = true;
            }
        },

        reset_accordion: function () {
            if(this.is_set_accordion){
                this.$footer_block.removeClass('footer-block--is-collapsed').removeClass('footer-block--not-collapsed');
                this.$footer_block_content.css({
                    height: 'auto'
                });

                this.is_set_accordion = false;
            }
        },

        handle_click: function () {
            if(this.$footer_block.hasClass('footer-block--is-collapsed')){
                this.open_accordion();
            } else {
                this.close_accordion();
            }
        },

        close_accordion: function () {
            this.$footer_block.addClass('footer-block--is-collapsed').removeClass('footer-block--not-collapsed');
            this.$footer_block_content.stop().animate({
                height: 0
            }, 300);
        },

        open_accordion: function () {
            this.$footer_block.removeClass('footer-block--is-collapsed').addClass('footer-block--not-collapsed');
            this.$footer_block_content.stop().animate({
                height: this.$footer_block_content.get(0).scrollHeight
            }, 300);
        }

    });

    return FooterAccordion;
});
