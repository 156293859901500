;
define(['jquery', 'stapes'], function ($, Stapes, undefined) {
  "use strict";

  var singleton;

  var Responsive = Stapes.subclass({
    _defaults: {
      resolutions: {
        xs: 550,
        sm: 768,
        md: 984
      },
      target: 'body'
    },

    constructor: function (options) {
      this.set({
        options: $.extend({}, this._defaults, options)
      });
      this.set({
        current: this._getCurrent()
      });
      this._listen();
    },

    _getCurrent: function () {
      var current = 'lg';
      var found = -1;
      var currentWidth = window.innerWidth;
      var resolutions = this.get('options').resolutions;
      for (var res in resolutions) {
        if (resolutions[res] > currentWidth && (resolutions[res] < found || found == -1)) {
          current = res;
          found = resolutions[res];
        }
      }
      return current;
    },

    _respond: function (event) {
      var oldRes = this.get('current');
      var newRes = this._getCurrent();
      if (oldRes != newRes) {
        $(window).trigger('resolutionChanged.responsive.oekotest', {
          oldResolution: oldRes,
          newResolution: newRes
        });
        this.set({current: newRes});
      }
    },

    _listen: function () {
      var self = this;
      $(window).on('resize', function (e) {
        self._respond(e);
      });
    }
  });


  function init(options) {
    if (singleton === undefined)
      singleton = new Responsive(options);
    return singleton;
  }

  return {
    init: init
  };
});
